import React, { useContext } from 'react';
import { graphql, PageProps } from 'gatsby';
import OthersDetail from '@/components/Others/OthersDetail';
import { Context } from '@/components/Layout';

const OthersPage: React.FC<PageProps<GatsbyTypes.OthersPageQuery>> = ({
  data,
}) => {
  const loading = useContext(Context);
  return !loading && <OthersDetail data={data} />;
};

export default OthersPage;

export const query = graphql`
  query OthersPage($OthersPageId: String!) {
    microcmsOthers(othersId: { eq: $OthersPageId }) {
      id
      othersId
      title
      titleEn
      body {
        rich
        plain
        contact {
          button
          tayori
          title
          id
        }
        file {
          url
        }
        fileLabel
        asset {
          assetBody {
            rich
            plain
          }
        }
      }
      description
      keyword
    }
  }
`;
