// ■コンポーネント説明
// その他コンテンツ詳細表示用コンポーネント

import React from 'react';
import SEO from '@/components/seo';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import CDNReplace from '@/components/CDNReplace';

import * as pageHeaderDetail from '@/styles/object/page-header-detail.module.scss';

export interface OthersDetailProps {
  data: GatsbyTypes.OthersPageQuery;
}

const OthersDetail: React.FC<OthersDetailProps> = (props) => {
  const { data } = props;

  return (
    <>
      <SEO
        title={`${data.microcmsOthers.title}`}
        description={`${data.microcmsOthers.description}`}
        keyword={`${data.microcmsOthers.keyword}`}
      />
      <div className="page-wrap --detail --common">
        <div className="page-wrap__wave" />
        <div className="page-wrap__bg" />
        <div className="page-contents-box">
          <header
            className={pageHeaderDetail.page_header_detail}
            style={{ border: `0`, paddingBottom: `0` }}
          >
            <h1 className={pageHeaderDetail.page_header_detail__title}>
              {data.microcmsOthers.title}
            </h1>
          </header>
          <div className="page-contents-body">
            <div className="editor">
              {data.microcmsOthers.body &&
                data.microcmsOthers.body.map((body) => {
                  let output = ``;
                  if (body.rich) {
                    output = body.rich;
                  }
                  if (body.plain) {
                    output = body.plain;
                  }
                  if (body.asset) {
                    const assetArray = body.asset.assetBody;
                    assetArray.forEach((elem) => {
                      if (elem.rich) {
                        output += elem.rich;
                      }
                      if (elem.plain) {
                        output += elem.plain;
                      }
                    });
                  }
                  if (body.contact) {
                    let contactBtn = ``;
                    contactBtn += `<a href="/contact/${body.contact.id}/" class="button-02 js-hover-circle" data-circle-type="reverse-button" data-circle-inner="arrow-right-orange">${body.contact.button}</a>`;
                    output += `<div class="btn">${contactBtn}</div>`;
                  }
                  if (body.file) {
                    output += `<a href="${body.file.url}" target="_blank">${body.fileLabel}</a>`;
                  }
                  return (
                    <div
                      key={output}
                      className="section"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: CDNReplace(output) }}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <UIBreadcrumb
        UpperItem={[]}
        CurrentItem={data.microcmsOthers.title || data.microcmsOthers.titleEn}
      />
    </>
  );
};

export default OthersDetail;
